/* globals module */
module.exports = function prettifyJson(json, options) {
  const cache = []
  options = options || {}
  let indent = options.indent
  if (indent == null) {
    indent = 3
  }
  return JSON.stringify(
    json,
    (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found, discard key
          return
        }
        // Store value in our collection
        cache.push(value)
      }
      return value
    },
    indent
  )
}
