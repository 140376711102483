/* eslint-disable import/extensions */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

// these two imports are the new equivalent of import "babel-polyfill"
// but they don't seem to be necessary in centene:
import 'core-js/stable' // or modules/es7';
// import 'regenerator-runtime';

if (!location.origin) {
  // @ts-ignore
  location.origin = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
}

// @ts-ignore
window.isIE11 = !!window.MSInputMethodContext && !!document.documentMode
